import { ControllerParams } from '@wix/yoshi-flow-editor';
import { encodeBase64Url, decodeBase64Url } from '../../../services/encode-base64';
import { CheckoutData, StatusData, SubPage } from '../../../types/common';
import { AppSectionParamsDecodeError, CheckoutDataDecodeError, StatusDataDecodeError } from '../../../utils/errors';
import { extractIntegrationData, integrationDataToAppSectionParams } from '../../../utils/integrationData';

export class Navigation {
  constructor(
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected appDefinitionId: string,
  ) {}

  async setCurrentPath(subPage: SubPage) {
    const { relativeUrl } = await this.wixCodeApi.site.getSectionUrl({
      appDefinitionId: this.appDefinitionId,
      sectionId: 'membership_plan_picker_tpa',
    });
    this.wixCodeApi.location.to!((relativeUrl ?? '') + subPageToPath(subPage));
  }

  getCurrentSubPage(): SubPage {
    const { location } = this.wixCodeApi;
    const { path } = location;
    if (path[1] === 'payment') {
      try {
        return { name: 'checkout', checkoutData: decodeBase64Url(path[2]) as CheckoutData };
      } catch (e) {
        throw new CheckoutDataDecodeError(e.message);
      }
    } else if (path[1] === 'status') {
      try {
        return { name: 'status', statusData: decodeBase64Url(path[2]) as StatusData };
      } catch (e) {
        throw new StatusDataDecodeError(e.message);
      }
    } else {
      const params = parseAppSectionParams(location.query.appSectionParams);
      const pageId = extractRestrictedPageId(params);
      const integrationData = extractIntegrationData(params);
      if (pageId) {
        return { name: 'restricted', pageId, integrationData };
      }
      return { name: 'list', integrationData };
    }
  }
}

function subPageToPath(subPage: SubPage): string {
  switch (subPage.name) {
    case 'checkout':
      return '/payment/' + encodeBase64Url(subPage.checkoutData);
    case 'status':
      return '/status/' + encodeBase64Url(subPage.statusData);
    case 'restricted':
      return '/restricted';
    default:
    case 'list':
      return '?' + integrationDataToAppSectionParams(subPage.integrationData);
  }
}

function extractRestrictedPageId(appSectionParams: Record<string, string>) {
  return appSectionParams.restrictedPageId;
}

function parseAppSectionParams(value: string): Record<string, string> {
  try {
    return value ? JSON.parse(value) : {};
  } catch (e) {
    throw new AppSectionParamsDecodeError(e.message);
  }
}
