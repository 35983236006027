import {
  memberGetOrder,
  getOnlineOrderPreview,
  createOnlineOrder,
  changeStartDate,
  getPricePreview,
} from '@wix/ambassador-pricing-plans-v2-order/http';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import { PurchaseLimitExceededError } from '../utils/errors';

export class OrdersApi {
  constructor(protected httpClient: IHttpClient) {}

  async getOrder(id: string) {
    const response = await this.httpClient.request(memberGetOrder({ id }));
    const { order } = response.data;
    return order;
  }

  public async createOrder(planId: string) {
    const previewResponse = await this.httpClient.request(
      getOnlineOrderPreview({
        planId,
        startDate: new Date(),
      }),
    );
    const { purchaseLimitExceeded } = previewResponse.data;
    if (purchaseLimitExceeded) {
      throw new PurchaseLimitExceededError();
    }

    const response = await this.httpClient.request(createOnlineOrder({ planId }));
    const { order } = response.data;
    return order;
  }

  public async updateOrderValidFrom(orderId: string, validFrom: Date) {
    const response = await this.httpClient.request(
      changeStartDate({
        orderId,
        startDate: validFrom,
      }),
    );
    const { order } = response.data;
    return order;
  }

  public async getPricePreview(planId: string) {
    const response = await this.httpClient.request(getPricePreview({ planId }));
    const { price } = response.data;
    return price;
  }
}

export function createOrdersApi(httpClient: IHttpClient) {
  return new OrdersApi(httpClient);
}
